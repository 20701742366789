import React from 'react';
import { useHistory } from 'react-router-dom';

import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import EditMusic from './pages/EditMusic';
import swal from 'sweetalert';
import '../App.css'
import axios from 'axios';

import { CTable, CTableRow, CTableHead, CTableHeaderCell, CTableDataCell, CTableBody, CButton, CPagination, CPaginationItem } from '@coreui/react';
import Pagination from './Pagination';

var api_url = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = process.env.REACT_APP_API_URL;


function ManageMusics(props) {

  const token = localStorage.getItem('token');

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [musics, setMusics] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [total, setTotal] = useState([]);
  const [links, setLinks] = useState([]);
  const [next_number, setNext] = useState([]);
  const [previous_number, setPrevious] = useState([]);

  console.log(props);


  if(typeof props.match.params.page === "undefined"){
    var url = "/api/get_musics.php";
  }else{
    var url = "/api/musics?page=" + props.match.params.page;
  }


  if(typeof props.match.params.q !== "undefined"){
    var q = props.match.params.q;
    
    var url = `/api/musics/search/${q}`;
  }
  console.log(url);

  useEffect(() => {
    axios.get(url,
      {
        headers: {
          'Accept': 'application/json'
        }
      }
    ).then(res => {
      console.log(res);
      if (res.status === 200 && res.data.success === 1) {
        if(typeof props.match.params.q !== "undefined"){
          setMusics(res.data);
        }else{
          setMusics(res.data.data);
        }
        

        if(typeof res.data.from !== "undefined"){
          setFrom(res.data.from);
          setTo(res.data.to);
          setTotal(res.data.total);
          setLinks(res.data.links);

          if(res.data.next_page_url !== null){
            var next_page_url_parts = res.data.next_page_url.split("?");
            setNext(next_page_url_parts[1].replace("page=", ""));
          }else{
            setNext("");
          }
          

          if(res.data.prev_page_url !== null){
            var previous_page_url_parts = res.data.prev_page_url.split("?");
            setPrevious(previous_page_url_parts[1].replace("page=", ""));
          }else{
            setPrevious("");
          }
        }

        
        setLoading(false);
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var audio;

  const playAudio = (e, audio_src) => {
    audio = new Audio(api_url + audio_src);
    

    if(e.target.text === "PLAY"){
        audio.play();
        e.target.text = "PAUSE";
        
    }else{
        audio.pause();
        e.target.text = "PLAY";
        
    }
    
  }


  const deleteMusic = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Deleting";


    swal({
      title: "Are you sure you want to delete this music?",
      text: "You will not be able to recover this music",
      icon: "warning",
      buttons: [
        'Cancel',
        'DELETE!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        axios.get(`/api/delete_music.php?id=${id}`, {
          headers: {
            'Accept': 'application/json',
          }
        }).then(res => {
          console.log(res);
          if (res.status === 200) {
            swal("Deleted!", "Deleted successfully", "success");
            thisClicked.closest("tr").remove();
          } else if (res.status === 404) {
            swal("Error", res.data.message, "error");
            thisClicked.innerText = "Delete";
          }
        });
      } else {
        thisClicked.innerText = "Delete";
      }
    });

  }

  const handleSearch = (e) => {
    e.persist();
    setKeyword(e.target.value);
  }



  const doSearch = (e) => {
    e.preventDefault();

    const data = {
      q: keyword
    }

    history.push(`/search/type/musics/q/${keyword}`)
  }

  // console.log(links);


  if (loading) {
    return <h4>Loading Music Data...</h4>
  } else {
    var music_HTMLTABLE = "";

    music_HTMLTABLE = musics.map((item, index) => {
      return (
        <CTableRow key={index}>
          <CTableDataCell>{item.id}</CTableDataCell>
          
          <CTableDataCell>
            <audio controls>
                  <source src={api_url + item.audio} type="audio/mp3"/>
              </audio>
                {/* <CButton onClick={(e) => playAudio(e, item.audio)} data-src={item.audio} component="a"  color="primary" size="sm" >PLAY</CButton> */}
            </CTableDataCell>
          
          <CTableDataCell>
            <CButton onClick={(e) => deleteMusic(e, item.id)} color="danger" size="sm" >Delete</CButton>
          </CTableDataCell>
        </CTableRow>
      );
    });
  }

  links.map(function (link, index) {
    links[index].label = link.label.replace("&laquo;", "<").replace("&raquo;", ">");
  })

  return (
    <div className='container_wrapper'>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4>Manage Music</h4>
              </div>
              <div className="card-header">
                {/* <form className='float-start' >
                  <input type="text" name='q' defaultValue="" className='mr-2' onChange={handleSearch} />
                  <CButton type='button' onClick={doSearch} color="primary" size="sm" className='float-end'>Search</CButton>
                </form> */}

                <CButton component="a" href={'/'} color="danger" size="sm" className='float-end ms-2'>Back</CButton>
                <CButton component="a" href={'/add-music'} color="success" size="sm" className='float-end'>Add Music</CButton>
              </div>
              <div className="card-body table-responsive">

                <CTable hover striped small bordered>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>ID</CTableHeaderCell>
                      <CTableHeaderCell>Audio</CTableHeaderCell>
                      <CTableHeaderCell>Delete</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {music_HTMLTABLE}
                  </CTableBody>
                </CTable>

              </div>
              {
                // typeof props.match.params.q === "undefined" ? 

                //     (<Pagination base_url="manage-musics" from={from} to={to} total={total} links={links} previous_number={previous_number} next_number={next_number}></Pagination>)
                //   :
                //   <div/>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageMusics;