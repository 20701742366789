import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import '../App.css';
import Select from 'react-select';
import $ from 'jquery';

function AddCollection() {

    const history = useHistory();
    const [errorInput, setError] = useState([]);
    const [options, setOptions] = useState([]);
    const [image, setImage] = useState([]);

    const [collectionInput, setCollection] = useState({
        name: '',
        description: '',
        categories: '',
        error_list: [],
    });

    const token = localStorage.getItem('token');
    var url = "/api/get_categories_dropdown.php";
    useEffect(() => {
        
        axios.get(url,
          {
            headers: {
              'Accept': 'application/json'
            }
          }
        ).then(res => {
          console.log(res);
          if (res.status === 200) {
            
            setOptions(res.data.data);
          };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const handleInput = (e) => {
        e.persist();
        setCollection({...collectionInput, [e.target.name]: e.target.value });
        setImage(e.target.files[0]);
    }

    const handleChange = (options) => {
        // collectionInput.categories = $('#categories').value;
        // console.log(options);
        collectionInput.categories = options.map(a => a.value);
        console.log(collectionInput);
    }

    const handleInput2 = (e) => {
        e.persist();
        setCollection({...collectionInput, [e.target.name]: e.target.value });
    }

    const saveCollection = (e) => {
        e.preventDefault();

        const fd = new FormData();

        console.log(collectionInput);

        fd.append('name', collectionInput.name);
        fd.append('description', collectionInput.description);
        fd.append('categories', collectionInput.categories);
        fd.append('image', image);
        
        axios.post(`/api/add_collection.php`, fd,
        {
            headers: {
                'Accept': 'application/json'
            }
        }).then(res => {
            console.log(res);
            if(res.status === 200 && res.data.success === 1)
            {
                swal("Success!",res.statusText,"success");
                setCollection({
                    name: '',
                    description: '',
                    error_list: [],
                });
                history.push('/manage-collections');
            }
            else if(res.data.status === 422)
            {
                setCollection({...collectionInput, error_list: res.data.validate_err });
            }
        }).catch(function (error) {
            if (error.response) {
                var errors = error.response.data.errors;
                var err_txt = "";
                for (const property in errors) {
                    err_txt+= property  + "-" + errors[property];
                }

              swal(error.response.data.message, err_txt, "error");
            }
        });
    }

    return (
        <div className='container_wrapper'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 mx-auto">
                        <div className="card text-left">
                            <div className="card-header">
                                <h4>Add Collection 
                                    <Link to={'/manage-collections'} className="btn btn-danger btn-sm float-end"> BACK</Link>
                                </h4>
                            </div>
                            <div className="card-body">
                                
                                <form onSubmit={saveCollection} encType="multipart/form-data">
                                    <div className="form-group mb-3">
                                        <label>Name</label>
                                        <input autoComplete='off' type="text" name="name" onChange={handleInput} value={collectionInput.name} className="form-control" />
                                        <span className="text-danger">{collectionInput.error_list.name}</span>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>Description</label>
                                        <textarea rows={5} autoComplete='off' name="description" onChange={handleInput} value={collectionInput.description} className="form-control"></textarea>
                                        <span className="text-danger">{collectionInput.error_list.description}</span>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>Image</label>
                                        <input type="file" name="image" onChange={handleInput} value={collectionInput.image} className="form-control" />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>Categories</label>
                                        <Select
                                            onChange={handleChange}
                                            isMulti
                                            name="categories"
                                            id='categories'
                                            options={options}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                    
                                    

                                    
                                    <div className="form-group mb-3">
                                        <button type="submit" className="btn btn-primary">Save Collection</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AddCollection;