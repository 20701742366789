import React from 'react';

import { NavDropdownMenu } from "react-bootstrap-submenu";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";

import { useEffect, useState } from "react";
import { CNavbar, CContainer, CNavbarBrand, CNavbarToggler, CCollapse, CNavItem, CNavLink, CNavbarNav, CDropdown, CDropdownMenu, CDropdownItem, CDropdownDivider, CForm, CDropdownToggle } from '@coreui/react'

const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/";
};


function Navigation() {
    const [visible, setVisible] = useState(false);
    return (

        // <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        //     <Navbar.Brand href="#home">NuLandia Admin</Navbar.Brand>
        //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        //     <Navbar.Collapse id="responsive-navbar-nav">
        //         <Nav className="mr-auto">
        //             <Nav.Link href="/">Home</Nav.Link>
        //             <NavDropdownMenu title="Manage" id="collasible-nav-dropdown">
        //                 <NavDropdown.Item href="/manage-users">Users</NavDropdown.Item>
        //                 <NavDropdown.Item href="/manage-worlds">Worlds</NavDropdown.Item>
        //             </NavDropdownMenu>
        //             <Nav.Link href="/logout" onClick={handleLogout}>Logout</Nav.Link>
        //         </Nav>
        //     </Navbar.Collapse>
        // </Navbar>


    <CNavbar expand="lg" colorScheme="dark" className="bg-dark">
      <CContainer fluid>
        <CNavbarBrand href="/">Admin</CNavbarBrand>
        <CNavbarToggler
          aria-label="Toggle navigation"
          aria-expanded={visible}
          onClick={() => setVisible(!visible)}
        />
        <CCollapse className="navbar-collapse" visible={visible}>
          <CNavbarNav>
            <CNavItem>
              <CNavLink href="/" active>
                Home
              </CNavLink>
            </CNavItem>
            <CDropdown variant="nav-item" popper={false}>
              <CDropdownToggle color="secondary">Manage</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem href="/manage-categories">Categories</CDropdownItem>
                {/* <CDropdownItem href="/manage-timers">Timers</CDropdownItem>
                <CDropdownItem href="/manage-meditations">Meditations</CDropdownItem>
                <CDropdownItem href="/manage-affirmations">Affirmations</CDropdownItem> */}
                <CDropdownItem href="/manage-backgrounds">Backgrounds</CDropdownItem>
                <CDropdownItem href="/manage-musics">Music</CDropdownItem>
                <CDropdownItem href="/manage-collections">Collections</CDropdownItem>
                {/* <CDropdownItem href="/manage-users">Users</CDropdownItem>
                <CDropdownItem href="/manage-worlds">Worlds</CDropdownItem>
                <CDropdownItem href="/manage-statuses">Statuses</CDropdownItem>
                <CDropdownItem href="/manage-purchase-types">Purchase Types</CDropdownItem>
                <CDropdownItem href="/manage-avatar-types">Avatar Types</CDropdownItem>
                <CDropdownItem href="/manage-nu-packs">Nu Packs</CDropdownItem>
                <CDropdownItem href="/manage-blockchains">Blockchains</CDropdownItem>
                <CDropdownItem href="/manage-business-models">Business Models</CDropdownItem>
                <CDropdownItem href="/manage-avatars">Avatars</CDropdownItem>
                <CDropdownItem href="/manage-assets">Assets</CDropdownItem>
                <CDropdownItem href="/manage-pricings">Pricings</CDropdownItem>
                <CDropdownItem href="/manage-newsletter-signups">Newsletter Signups</CDropdownItem>
                <CDropdownItem href="/manage-contacts">Contacts</CDropdownItem>
                <CDropdownItem href="/manage-whitelist-users">Whitelist Users</CDropdownItem>
                <CDropdownItem href="/manage-name-types">Name Types</CDropdownItem>
                <CDropdownItem href="/manage-faqs">FAQs</CDropdownItem>
                <CDropdownItem href="/manage-videos">Videos</CDropdownItem>
                <CDropdownItem href="/manage-tickets">Support Tickets</CDropdownItem>
                <CDropdownItem href="/annual-awards">Annual Awards</CDropdownItem>
                <CDropdownItem href="/monthly-awards">Monthly Awards</CDropdownItem>
                <CDropdownItem href="/manage-prizes">Prizes</CDropdownItem> */}
              </CDropdownMenu>
            </CDropdown>
            {/* <CNavItem>
              <CNavLink href="/reset-data" active>
                Reset Data
              </CNavLink>
            </CNavItem> */}
            {/* <CNavItem>
              <CNavLink onClick={handleLogout} active>
                Logout
              </CNavLink>
            </CNavItem> */}
          </CNavbarNav>
        </CCollapse>
      </CContainer>
    </CNavbar>
    



        // <div className="pb-5">
        //     <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        //         <div className="container">
        //             <Link className="navbar-brand" to="/">Navbar</Link>

        //             <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        //                 <span className="navbar-toggler-icon"></span>
        //             </button>
        //             <div className="collapse navbar-collapse" id="navbarSupportedContent">
        //                 <ul className="navbar-nav ms-auto mb-2 mb-lg-0 nav-dropdown ">
        //                     <li className="nav-item">
        //                         <Link className="nav-link" to="/">Home</Link>
        //                     </li>
        //                     <li className="nav-item dropdown">
        //                         <Link className="nav-link dropdown-toggle" data-toggle="dropdown-submenu" aria-expanded="false" to="/#">Manage</Link>
        //                         <div class="dropdown-submenu">
        //                             <Link className="dropdown-item" to="/manage-users">Users</Link>
        //                             <Link className="dropdown-item" to="/manage-worlds">Worlds</Link>
        //                         </div>
        //                     </li>
        //                     <li className="nav-item">
        //                         <Link className="nav-link" to="/add-user">Add User</Link>
        //                     </li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </nav>
        // </div>
    );
}

export default Navigation;