import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import '../App.css';

function AddMusic() {

    const history = useHistory();
    const [errorInput, setError] = useState([]);
    const [audio, setAudio] = useState([]);
    const [musicInput, setMusic] = useState({
        name: '',
        details: '',
        error_list: [],
    });

    const token = localStorage.getItem('token');

    const handleInput = (e) => {
        e.persist();
        setMusic({...musicInput, [e.target.name]: e.target.value });
        console.log(e.target.files);
        // setImage(e.target.files[0]);
    }

    const handleInput2 = (e) => {
        e.persist();
        setMusic({...musicInput, [e.target.name]: e.target.value });
        console.log(e.target.files);
        setAudio(e.target.files[0]);
    }

    const saveMusic = (e) => {
        e.preventDefault();

        const fd = new FormData();

        fd.append('audio', audio);
        
        axios.post(`/api/add_music.php`, fd,
        {
            headers: {
                'Accept': 'application/json'
            }
        }).then(res => {
            console.log(res);
            if(res.status === 200 && res.data.success === 1)
            {
                swal("Success!",res.statusText,"success");
                setMusic({
                    name: '',
                    details: '',
                    error_list: [],
                });
                history.push('/manage-musics');
            }else if(res.data.success === 0){
                swal("Error", res.data.message, "error");
            }
            else if(res.data.status === 422)
            {
                setMusic({...musicInput, error_list: res.data.validate_err });
            }
        }).catch(function (error) {
            if (error.response) {
                var errors = error.response.data.errors;
                var err_txt = "";
                for (const property in errors) {
                    err_txt+= property  + "-" + errors[property];
                }

              swal(error.response.data.message, err_txt, "error");
            }
        });
    }

    return (
        <div className='container_wrapper'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 mx-auto">
                        <div className="card text-left">
                            <div className="card-header">
                                <h4>Add Music 
                                    <Link to={'/manage-musics'} className="btn btn-danger btn-sm float-end"> BACK</Link>
                                </h4>
                            </div>
                            <div className="card-body">
                                
                                <form onSubmit={saveMusic} encType="multipart/form-data">
                                    

                                    <div className="form-group mb-3">
                                        <label>Audio</label>
                                        <input type="file" name="audio" onChange={handleInput2} value={musicInput.audio} className="form-control" />
                                        <span className="text-danger">{errorInput.audio}</span>
                                    </div>

                                    <div className="form-group mb-3">
                                        <button type="submit" className="btn btn-primary">Save Music</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AddMusic;