import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';


import '../App.css';
function EditCategory(props) {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [categoryInput, setCategory] = useState([]);
    const [errorInput, setError] = useState([]);


    const token = localStorage.getItem('token');

    console.log(props);


    useEffect(() => {

        const category_id = props.match.params.id;
        axios.get(`/api/get_category.php?id=${category_id}`,
            {
                headers: {
                    'Accept': 'application/json'
                }
            }
        ).then(res => {
            console.log(res);
            if (res.status === 200 && res.data.success === 1) {
                setCategory(res.data.data);
                setLoading(false);
            }
            else if (res.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/manage-categories');
            }
        });

    }, [history]);

    const handleInput = (e) => {
        e.persist();
        setCategory({ ...categoryInput, [e.target.name]: e.target.value });
        console.log(categoryInput);
    }

    const handleInput2 = (e) => {
        e.persist();
        setCategory({...categoryInput, [e.target.name]: e.target.value });
        console.log(e.target.files);
    }

    const updateCategory = (e) => {
        e.preventDefault();
        // console.log(categoryInput);
        const category_id = categoryInput.id;
        // const data = categoryInput;

        const fd = new FormData();

        fd.append('name', categoryInput.name);

        console.log(fd);


        axios.post(`/api/add_category.php?update=1&id=${category_id}`, fd, {
            headers: {
                'Accept': 'application/json'
            }
            
        }).then(res => {
            console.log(res);
            if (res.status === 200) {
                swal("Success", "Successfully Updated", "success");
                setError([]);
                setTimeout(function(){history.push('/manage-categories')}, 2000);
            }
            else if (res.status === 422) {
                swal("All fields are mandatory", "", "error");
                setError(res.validationErrors);
            }
            else if (res.status === 404) {
                swal("Error", res.data.message, "error");
                // history.push('/manage-categories');
            }
        }).catch(function (error) {
            if (error.response) {
                var errors = error.response.data.errors;
                var err_txt = "";
                for (const property in errors) {
                    err_txt+= property  + "-" + errors[property];
                }

              swal(error.response.data.message, err_txt, "error");
            }
        });;
    }

    if (loading) {
        return <h4>Loading Edit Category Data...</h4>
    }

    return (
        <div className='container_wrapper'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>Edit Category
                                    <Link to={'/manage-categories'} className="btn btn-danger btn-sm float-end"> BACK</Link>
                                </h4>
                            </div>
                            <div className="card-body">

                                <form onSubmit={updateCategory}  encType="multipart/form-data">
                                <div className="form-group mb-3">
                                        <label>Name</label>
                                        <input type="text" name="name" onChange={handleInput} value={categoryInput.name} className="form-control" />
                                        {/* <span className="text-danger">{categoryInput.error_list.name}</span> */}
                                    </div>

                                    <div className="form-group mb-3">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default EditCategory;