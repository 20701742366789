import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import '../App.css';

function AddCategory() {

    const history = useHistory();
    const [errorInput, setError] = useState([]);
    const [image, setImage] = useState([]);
    const [audio, setAudio] = useState([]);
    const [categoryInput, setCategory] = useState({
        name: '',
        details: '',
        error_list: [],
    });

    const token = localStorage.getItem('token');

    const handleInput = (e) => {
        e.persist();
        setCategory({...categoryInput, [e.target.name]: e.target.value });
        console.log(e.target.files);
        
    }

    const handleInput2 = (e) => {
        e.persist();
        setCategory({...categoryInput, [e.target.name]: e.target.value });
        console.log(e.target.files);
        
    }

    const saveCategory = (e) => {
        e.preventDefault();

        const fd = new FormData();

        fd.append('name', categoryInput.name);
        
        axios.post(`/api/add_category.php`, fd,
        {
            headers: {
                'Accept': 'application/json'
            }
        }).then(res => {
            console.log(res);
            if(res.status === 200 && res.data.success === 1){
                swal("Success!",res.data.message,"success");
                // setCategory({
                //     name: '',
                //     error_list: [],
                // });
                history.push('/manage-categories');
            }
            else if(res.data.status === 422)
            {
                setCategory({...categoryInput, error_list: res.data.validate_err });
            }
        }).catch(function (error) {
            if (error.response) {
                var errors = error.response.data.errors;
                var err_txt = "";
                for (const property in errors) {
                    err_txt+= property  + "-" + errors[property];
                }

              swal(error.response.data.message, err_txt, "error");
            }
        });
    }

    return (
        <div className='container_wrapper'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 mx-auto">
                        <div className="card text-left">
                            <div className="card-header">
                                <h4>Add category 
                                    <Link to={'/manage-categories'} className="btn btn-danger btn-sm float-end"> BACK</Link>
                                </h4>
                            </div>
                            <div className="card-body">
                                
                                <form onSubmit={saveCategory} encType="multipart/form-data">
                                    <div className="form-group mb-3">
                                        <label>Name</label>
                                        <input autoComplete='off' type="text" name="name" onChange={handleInput} value={categoryInput.name} className="form-control" />
                                        <span className="text-danger">{categoryInput.error_list.name}</span>
                                    </div>

                                    <div className="form-group mb-3">
                                        <button type="submit" className="btn btn-primary">Save category</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AddCategory;