import React from 'react';
import { useHistory } from 'react-router-dom';

import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Editcategory from './pages/Editcategory';
import swal from 'sweetalert';
import '../App.css'
import axios from 'axios';

import { CTable, CTableRow, CTableHead, CTableHeaderCell, CTableDataCell, CTableBody, CButton, CPagination, CPaginationItem } from '@coreui/react';
import Pagination from './Pagination';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;



function ManageCategories(props) {

  const token = localStorage.getItem('token');

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [total, setTotal] = useState([]);
  const [links, setLinks] = useState([]);
  const [next_number, setNext] = useState([]);
  const [previous_number, setPrevious] = useState([]);

  console.log(props);


  if(typeof props.match.params.page === "undefined"){
    var url = "/api/get_categories.php";
  }else{
    var url = "/api/categories?page=" + props.match.params.page;
  }


  if(typeof props.match.params.q !== "undefined"){
    var q = props.match.params.q;
    
    var url = `/api/categories/search/${q}`;
  }
  console.log(url);

  useEffect(() => {
    axios.get(url,
      {
        headers: {
          'Accept': 'application/json'
        }
      }
    ).then(res => {
      console.log(res);
      if (res.status === 200) {
        if(typeof props.match.params.q !== "undefined"){
          setCategories(res.data);
        }else{
            if(res.data.data.length !== 0){
                setCategories(res.data.data);
            }else{
                setCategories([]);
            }
            
        }
        

        if(typeof res.data.from !== "undefined"){
          setFrom(res.data.from);
          setTo(res.data.to);
          setTotal(res.data.total);
          setLinks(res.data.links);

          if(res.data.next_page_url !== null){
            var next_page_url_parts = res.data.next_page_url.split("?");
            setNext(next_page_url_parts[1].replace("page=", ""));
          }else{
            setNext("");
          }
          

          if(res.data.prev_page_url !== null){
            var previous_page_url_parts = res.data.prev_page_url.split("?");
            setPrevious(previous_page_url_parts[1].replace("page=", ""));
          }else{
            setPrevious("");
          }
        }

        
        setLoading(false);
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const deleteCategory = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Deleting";


    swal({
      title: "Are you sure you want to delete this category?",
      text: "You will not be able to recover this category",
      icon: "warning",
      buttons: [
        'Cancel',
        'DELETE!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        axios.get(`/api/delete_category.php?id=${id}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }).then(res => {
          console.log(res);
          if (res.status === 200) {
            swal("Deleted!", "Deleted successfully", "success");
            thisClicked.closest("tr").remove();
          } else if (res.status === 404) {
            swal("Error", res.data.message, "error");
            thisClicked.innerText = "Delete";
          }
        });
      } else {
        thisClicked.innerText = "Delete";
      }
    });

  }

  const handleSearch = (e) => {
    e.persist();
    setKeyword(e.target.value);
  }



  const doSearch = (e) => {
    e.preventDefault();

    const data = {
      q: keyword
    }

    history.push(`/search/type/categories/q/${keyword}`)
  }

  // console.log(links);


  if (loading) {
    return <h4>Loading categories Data...</h4>
  } else {
    var category_HTMLTABLE = "";

    category_HTMLTABLE = categories.map((item, index) => {
      return (
        <CTableRow key={index}>
          <CTableDataCell>{item.id}</CTableDataCell>
          <CTableDataCell>{item.name}</CTableDataCell>
          <CTableDataCell>
            <CButton component="a" href={`/edit-category/id/${item.id}`} color="primary" size="sm" >Edit</CButton>
          </CTableDataCell>
          <CTableDataCell>
            <CButton onClick={(e) => deleteCategory(e, item.id)} color="danger" size="sm" >Delete</CButton>
          </CTableDataCell>
        </CTableRow>
      );
    });
  }

  links.map(function (link, index) {
    links[index].label = link.label.replace("&laquo;", "<").replace("&raquo;", ">");
  })

  return (
    <div className='container_wrapper'>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4>Manage Categories</h4>
              </div>
              <div className="card-header">
                {/* <form className='float-start' >
                  <input type="text" name='q' defaultValue="" className='mr-2' onChange={handleSearch} />
                  <CButton type='button' onClick={doSearch} color="primary" size="sm" className='float-end'>Search</CButton>
                </form> */}

                <CButton component="a" href={'/'} color="danger" size="sm" className='float-end ms-2'>Back</CButton>
                <CButton component="a" href={'/add-category'} color="success" size="sm" className='float-end'>Add category</CButton>
              </div>
              <div className="card-body table-responsive">

                <CTable hover striped small bordered>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>ID</CTableHeaderCell>
                      <CTableHeaderCell>Name</CTableHeaderCell>
                      <CTableHeaderCell>Edit</CTableHeaderCell>
                      <CTableHeaderCell>Delete</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {category_HTMLTABLE}
                  </CTableBody>
                </CTable>

              </div>
              {
                // typeof props.match.params.q === "undefined" ? 

                //     (<Pagination base_url="manage-categories" from={from} to={to} total={total} links={links} previous_number={previous_number} next_number={next_number}></Pagination>)
                //   :
                //   <div/>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageCategories;