import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import '../App.css';

function AddBackground() {

    const history = useHistory();
    const [errorInput, setError] = useState([]);
    const [image, setImage] = useState([]);
    const [backgroundInput, setBackground] = useState({
        name: '',
        details: '',
        error_list: [],
    });

    const token = localStorage.getItem('token');

    const handleInput = (e) => {
        e.persist();
        setBackground({...backgroundInput, [e.target.name]: e.target.value });
        console.log(e.target.files);
        setImage(e.target.files[0]);
    }

    const handleInput2 = (e) => {
        e.persist();
        setBackground({...backgroundInput, [e.target.name]: e.target.value });
        console.log(e.target.files);
    }

    const saveBackground = (e) => {
        e.preventDefault();

        const fd = new FormData();

        fd.append('image', image);
        
        axios.post(`/api/add_background.php`, fd,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                
            }
        }).then(res => {
            console.log(res);
            if(res.status === 200 && res.data.success === 1)
            {
                swal("Success!",res.statusText,"success");
                setBackground({
                    name: '',
                    details: '',
                    error_list: [],
                });
                history.push('/manage-backgrounds');
            }else if(res.data.success === 0){
                swal("Error", res.data.message, "error");
            }
            else if(res.data.status === 422)
            {
                setBackground({...backgroundInput, error_list: res.data.validate_err });
            }
        }).catch(function (error) {
            if (error.response) {
                var errors = error.response.data.errors;
                var err_txt = "";
                for (const property in errors) {
                    err_txt+= property  + "-" + errors[property];
                }

              swal(error.response.data.message, err_txt, "error");
            }
        });
    }

    return (
        <div className='container_wrapper'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 mx-auto">
                        <div className="card text-left">
                            <div className="card-header">
                                <h4>Add Background 
                                    <Link to={'/manage-backgrounds'} className="btn btn-danger btn-sm float-end"> BACK</Link>
                                </h4>
                            </div>
                            <div className="card-body">
                                
                                <form onSubmit={saveBackground} encType="multipart/form-data">
                                    

                                    <div className="form-group mb-3">
                                        <label>Background Image</label>
                                        <input type="file" name="image" onChange={handleInput} value={backgroundInput.image} className="form-control" />
                                        <span className="text-danger">{errorInput.image}</span>
                                    </div>


                                    <div className="form-group mb-3">
                                        <button type="submit" className="btn btn-primary">Save Background</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AddBackground;