import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Select from 'react-select';
import $ from 'jquery';

import '../App.css';
function EditCollection(props) {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [collectionInput, setCollection] = useState([]);
    
    const [image, setImage] = useState([]);
    const [errorInput, setError] = useState([]);
    
    const [options, setOptions] = useState([]);
    const [default_options, setDefaultOptions] = useState([]);


    const token = localStorage.getItem('token');

    console.log(props);

    


    useEffect(() => {
        const collection_id = props.match.params.id;
        

        axios.get("/api/get_categories_dropdown.php",
            {
              headers: {
                'Accept': 'application/json'
              }
            }
          ).then(res => {
            console.log(res);
            if (res.status === 200) {
              
              setOptions(res.data.data);

              axios.get(`/api/get_collection.php?id=${collection_id}`,
                    {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                ).then(res => {

                    console.log(res);
                    var def_options = [];
                    if (res.status === 200) {
                        setCollection(res.data.data);
                        res.data.data.categories.map((cat) => {
                            return def_options.push(Object.assign({}, {value: cat.value, label: cat.label}));
                        });
                        setDefaultOptions(def_options);

                        setDefaultOptions(res.data.data.categories);
                        setLoading(false);
                    }
                    else if (res.status === 404) {
                        swal("Error", res.data.message, "error");
                        history.push('/manage-collections');
                    }
                });
            };
          });

        // const collection_id = props.match.params.id;
        // axios.get(`/api/get_collection.php?id=${collection_id}`,
        //     {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     }
        // ).then(res => {

        //     console.log(res);
        //     var def_options = [];
        //     if (res.status === 200) {
        //         setCollection(res.data.data);
        //         // res.data.data.categories.map((cat) => {
        //         //     def_options.push(Object.assign({}, {value: cat.value, label: cat.label}));
        //         // });
        //         // setDefaultOptions(def_options);
        //         // setDefaultOptions(res.data.data.categories);
        //         setLoading(false);
        //     }
        //     else if (res.status === 404) {
        //         swal("Error", res.data.message, "error");
        //         history.push('/manage-collections');
        //     }
        // });

        setLoading(false);

    }, [history]);

    const handleInput = (e) => {
        e.persist();
        setCollection({ ...collectionInput, [e.target.name]: e.target.value });
        
        setImage(e.target.files[0]);
    }

    const handleChange = (options) => {
        // collectionInput.categories = $('#categories').value;
        // console.log(options);
        collectionInput.categories = options.map(a => a.value);
        console.log(collectionInput);
    }
    const handleInput2 = (e) => {
        e.persist();
        setCollection({...collectionInput, [e.target.name]: e.target.value });
    }

    const updateCollection = (e) => {
        e.preventDefault();
        // console.log(collectionInput);
        const collection_id = collectionInput.id;
        // const data = collectionInput;

        const fd = new FormData();

        fd.append('name', collectionInput.name);
        fd.append('description', collectionInput.description);
        fd.append('image', image);
        fd.append('categories', collectionInput.categories);

        console.log(fd);


        axios.post(`/api/add_collection.php?update=${collection_id}&id=${collection_id}`, fd, {
            headers: {
                'Accept': 'application/json'
            }
            
        }).then(res => {
            console.log(res);
            if (res.status === 200) {
                swal("Success", "Successfully Updated", "success");
                setError([]);
                history.push('/manage-collections');
            }
            else if (res.status === 422) {
                swal("All fields are mandatory", "", "error");
                setError(res.validationErrors);
            }
            else if (res.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/manage-collections');
            }
        }).catch(function (error) {
            if (error.response) {
                var errors = error.response.data.errors;
                var err_txt = "";
                for (const property in errors) {
                    err_txt+= property  + "-" + errors[property];
                }

              swal(error.response.data.message, err_txt, "error");
            }
        });;
    }

    if (loading) {
        return <h4>Loading...</h4>
    }

    return (
        <div className='container_wrapper'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>Edit Collection
                                    <Link to={'/manage-collections'} className="btn btn-danger btn-sm float-end"> BACK</Link>
                                </h4>
                            </div>
                            <div className="card-body">

                                <form onSubmit={updateCollection}  encType="multipart/form-data">
                                <div className="form-group mb-3">
                                        <label>Name</label>
                                        <input type="text" name="name" onChange={handleInput} value={collectionInput.name} className="form-control" />
                                        {/* <span className="text-danger">{collectionInput.error_list.name}</span> */}
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>Description</label>
                                        <textarea type="text" name="description" onChange={handleInput} value={collectionInput.description} className="form-control" ></textarea>
                                        {/* <span className="text-danger">{collectionInput.error_list.description}</span> */}
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>Current Categories</label><br/>
                                        <label>
                                        {
                                            default_options.map(option => option.label).join(', ')
                                        }
                                        </label>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>Categories</label>
                                        {console.log(options)}
                                        
                                        <Select
                                            defaultValue={default_options}
                                            onChange={handleChange}
                                            isMulti
                                            name="categories"
                                            id='categories'
                                            options={options}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>Image</label>
                                        <input type="file" name="image" onChange={handleInput} className="form-control" />
                                        {/* <span className="text-danger">{errorInput.image}</span> */}
                                    </div>
                                    

                                    <div className="form-group mb-3">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default EditCollection;