import './App.css';

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navigation from './pages/Navigation';

import "react-bootstrap-submenu/dist/index.css";


import Dashboard from './pages/Dashboard';
// import AddTimer from './pages/AddTimer';
// import ManageTimers from './pages/ManageTimers';
// import EditTimer from './pages/EditTimer';
// import AddMeditation from './pages/AddMeditation';
// import ManageMeditations from './pages/ManageMeditations';
// import EditMeditation from './pages/EditMeditation';
// import ManageAffirmations from './pages/ManageAffirmations';
// import AddAffirmation from './pages/AddAffirmation';
// import EditAffirmation from './pages/EditAffirmation';
import ManageCategories from './pages/ManageCategories';
import AddCategory from './pages/AddCategory';
import EditCategory from './pages/EditCategory';
import AddBackground from './pages/AddBackground';
import ManageBackgrounds from './pages/ManageBackgrounds';
import AddMusic from './pages/AddMusic';
import ManageMusics from './pages/ManageMusics';
import ManageCollections from './pages/ManageCollections';
import AddCollection from './pages/AddCollection';
import EditCollection from './pages/EditCollection';



function App() {
  const token = localStorage.getItem('token');

  useEffect(() => {
    document.title = "Admin";
    document.getElementsByTagName("META")[3].content="Admin";
  }, []);


  // if (!token) {
  //   return <Login />
  // } else {
    return (
      <div className="App">
        <Router >

          <Navigation />

          {/* <Sidebar /> */}


          <Switch>
            <Route exact path="/" component={Dashboard} />

            

            {/* <Route path="/add-timer" component={AddTimer} /> */}
            {/* <Route path="/edit-business-model/id/:id" component={EditBusinessModel} /> */}

{/* 
            <Route exact path="/manage-timers" component={ManageTimers} />

            <Route path="/add-timer" component={AddTimer} />
            <Route path="/edit-timer/id/:id" component={EditTimer} /> */}

            {/* <Route exact path="/manage-meditations" component={ManageMeditations} />

            <Route path="/add-meditation" component={AddMeditation} />
            <Route path="/edit-meditation/id/:id" component={EditMeditation} /> */}


            <Route exact path="/manage-categories" component={ManageCategories} />
            <Route path="/add-category" component={AddCategory} />
            <Route path="/edit-category/id/:id" component={EditCategory} />

            <Route exact path="/manage-collections" component={ManageCollections} />
            <Route path="/add-collection" component={AddCollection} />
            <Route path="/edit-collection/id/:id" component={EditCollection} />


            <Route exact path="/manage-backgrounds" component={ManageBackgrounds} />
            <Route path="/add-background" component={AddBackground} />

            <Route exact path="/manage-musics" component={ManageMusics} />
            <Route path="/add-music" component={AddMusic} />


            {/* <Route exact path="/manage-affirmations" component={ManageAffirmations} />

            <Route path="/add-affirmation" component={AddAffirmation} />
            <Route path="/edit-affirmation/id/:id" component={EditAffirmation} /> */}


            

          </Switch>
        </Router>
      </div>
    );
  // }

}

export default App;