import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';

import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import EditUser from './pages/EditUser';
import swal from 'sweetalert';
import '../App.css'

import { CCard, CCardImage, CCardBody, CCardTitle, CCardText, CRow, CCol, CWidgetStatsC, CContainer, CHeaderDivider, CLink } from '@coreui/react'
import { cilArrowRight, CIcon } from '@coreui/coreui';
import axios from 'axios';

// console.log(process.env.REACT_APP_API_URL);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;


function Dashboard() {

    const token = localStorage.getItem('token');
    const history = useHistory();
    const [worlds_count, setWorldsCount] = useState([]);
    const [buildings_count, setBuildingsCount] = useState([]);
    const [parcels_count, setParcelsCount] = useState([]);
    const [users_count, setUsersCount] = useState([]);
    const [active_users_count, setActiveUsersCount] = useState([]);
    const [newsletter_signups_count, setNewsletterSignupsCount] = useState([]);
    const [whitelist_users_count, setWhitelistUsersCount] = useState([]);

    var counts = [];


    console.log(axios.defaults.baseURL);


    useEffect(() => {
        // axios.get(`api/simple-count-total/worlds`,
        //     {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     }
        // ).then(res => {
        //     setWorldsCount(res.data.worlds);
        // });

        // axios.get(`api/simple-count-total/buildings`,
        //     {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     }
        // ).then(res => {
        //     setBuildingsCount(res.data.buildings);
        // });

        // axios.get(`api/simple-count-total/parcels`,
        //     {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     }
        // ).then(res => {
        //     setParcelsCount(res.data.parcels);
        // });


        // axios.get(`api/simple-count-total/users`,
        //     {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     }
        // ).then(res => {
        //     setUsersCount(res.data.users);
        // });

        // axios.get(`api/simple-count/users/active`,
        //     {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     }
        // ).then(res => {
        //     setActiveUsersCount(res.data.users);
        // });

        // axios.get(`api/count-newsletter-signups`,
        //     {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     }
        // ).then(res => {
        //     console.log(res);
        //     setNewsletterSignupsCount(res.data);
        // });

        // axios.get(`/api/whitelist-count`,
        //     {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     }
        // ).then(res => {
        //     console.log(res);
        //     setWhitelistUsersCount(res.data['whitelist count']);
        //     console.log(whitelist_users_count);
        // });



        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, []);

    // counts.push({ "label": "Worlds", "display": worlds_count, "color": "primary", "link" : "/manage-worlds" });
    // counts.push({ "label": "Buildings", "display": buildings_count, "color": "warning", "link" : "/manage-all-buildings" });
    // counts.push({ "label": "Parcels", "display": parcels_count, "color": "success", "link" : "/manage-all-parcels" });
    // counts.push({ "label": "Users", "display": users_count, "color": "secondary", "link" : "/manage-users" });
    // counts.push({ "label": "Active Users", "display": active_users_count, "color": "info", "link" : "/manage-users" });
    // counts.push({ "label": "Newsletter Signups", "display": newsletter_signups_count, "color": "primary", "link" : "/newsletter-signups" });
    // counts.push({ "label": "Whitelist Users", "display": whitelist_users_count, "color": "warning", "link" : "/whitelist-users" });

    // console.log(counts);


    return (
        <div className="Dashboard">


            <CContainer lg className='mt-4'>
                <CRow>
                    <h1>Dashboard</h1>
                </CRow>
            </CContainer>


            <CContainer lg className='mt-4' style={{display: 'none'}}>
                <CRow>
                    {/* {counts.map(function (count) {
                        return (
                        <CCol xs={4} key={count.label}>
                            <CLink
                                className="font-xs text-medium-emphasis text-decoration-none"
                                // href={count.link}
                                rel="noopener norefferer">
                                <CWidgetStatsC
                                    color={count.color}
                                    className="mb-3 fs-5 text-white"
                                    text="Widget helper text"
                                    title={count.label}
                                    value={count.display}
                                />
                                
                            </CLink>
                        </CCol>)
                    })} */}
                </CRow>
            </CContainer>
            
        </div>
    );
}

export default Dashboard;