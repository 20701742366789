import React from 'react';
import { useHistory } from 'react-router-dom';

import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import EditCollection from './pages/EditCollection';
import swal from 'sweetalert';
import '../App.css'
import axios from 'axios';

import $ from 'jquery';

import { CTable, CTableRow, CTableHead, CTableHeaderCell, CTableDataCell, CTableBody, CButton, CPagination, CPaginationItem } from '@coreui/react';
import Pagination from './Pagination';
import Swal from 'sweetalert2';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

var api_url = process.env.REACT_APP_API_URL;


function ManageCollections(props) {

  const token = localStorage.getItem('token');

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [total, setTotal] = useState([]);
  const [links, setLinks] = useState([]);
  const [next_number, setNext] = useState([]);
  const [previous_number, setPrevious] = useState([]);

  console.log(props);


  if(typeof props.match.params.page === "undefined"){
    var url = "/api/get_collections.php";
  }else{
    var url = "/api/collections?page=" + props.match.params.page;
  }


  if(typeof props.match.params.q !== "undefined"){
    var q = props.match.params.q;
    
    var url = `/api/collections/search/${q}`;
  }
  console.log(url);

//   $('.btn_view').on('click', function(e){
//     console.log($(this).parent().parent());
//     $(this).parent().parent().find('.affirmations_wrapper').slideToggle();

//   });

const handleClick = (e) => {
    const parentElement = e.target.parentElement.parentElement;
    const affirmationsWrapper = parentElement.querySelector('.affirmations_wrapper');
    affirmationsWrapper.style.display = (affirmationsWrapper.style.display === 'none') ? 'block' : 'none';
  };
  
  // Add onClick event listener to the elements with class 'btn_view'
//   const btnViewElements = document.querySelectorAll('.btn_view');
//   btnViewElements.forEach((btnViewElement) => {
//     btnViewElement.addEventListener('click', handleClick);
//   });

  async function editAffirmation(e){
    e.preventDefault();
    var value = e.target.dataset.value;
    
    var affirmation_id = e.target.dataset.affirmation_id;


    const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Edit Affirmation',
        inputValue: value,
        inputPlaceholder: 'Type affirmation text here...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        showCancelButton: true,
        confirmButtonText: 'Save'
      })
      
      if (text) {
        const fd = new FormData();


        fd.append('text', text);

        axios.post(`/api/update_affirmation.php?update=1&id=`+affirmation_id, fd, {
            // headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded'
            // }
        }).then(res => {
            console.log(res);
            if (res.data.success === 1) {
              swal("Success!", res.data.message, "success");
              window.location.reload();
            } else if (res.data.success === 0) {
              swal("Error!", res.data.message, "error");
            }
          }).catch(function (error) {
            if (error.response) {
              var errors = error.response.data.errors;
              var err_txt = "";
              for (const property in errors) {
                err_txt += property + "-" + errors[property];
              }

              swal(error.response.data.message, err_txt, "error");
            }
          });
      }
  }

  async function AddAffirmation(e){
    e.preventDefault();
    var collection_id = e.target.dataset.collection_id;

    const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'New Affirmation',
        inputPlaceholder: 'Type affirmation text here...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        showCancelButton: true,
        confirmButtonText: 'Save'
      })
      
      if (text) {
        const fd = new FormData();

        fd.append('text', text);
        fd.append('collection_id', collection_id);

        axios.post(`/api/add_collection_affirmation.php`, fd, {
            // headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded'
            // }
        }).then(res => {
            console.log(res);
            if (res.data.success === 1) {
              swal("Success!", res.data.message, "success");
            } else if (res.data.success === 0) {
              swal("Error!", res.data.message, "error");
            }
          }).catch(function (error) {
            if (error.response) {
              var errors = error.response.data.errors;
              var err_txt = "";
              for (const property in errors) {
                err_txt += property + "-" + errors[property];
              }

              swal(error.response.data.message, err_txt, "error");
            }
          });
      }
  }

  async function handleDragEnd(e){
    console.log(e);
  }


  /////////////////////////////////////////
  const handleDragStart = (e, collectionId, index) => {
    e.dataTransfer.setData('text/plain', JSON.stringify({ collectionId, index }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, collectionId, newIndex) => {
    e.preventDefault();
    const droppedData = JSON.parse(e.dataTransfer.getData('text/plain'));
    
    const { collectionId: oldCollectionId, index: oldIndex } = droppedData;

    console.log(oldCollectionId, collectionId);

    if (collectionId === oldCollectionId) {
        console.log("here");
      // Reordering within the same collection
      const updatedCollections = [...collections];
      const collection = updatedCollections.find((collection) => collection.id === collectionId);
      const [draggedItem] = collection.affirmations.splice(oldIndex, 1);
      collection.affirmations.splice(newIndex, 0, draggedItem);
      setCollections(updatedCollections);


      console.log("indexes:", oldIndex + " " + newIndex);

      // Reorder the divs visually in the DOM
      const affirmationWrapperSelector = `.affirmations_wrapper[data-collection_id="${collectionId}"] .affirmation_wrapper`;
      const $affirmationWrappers = $(affirmationWrapperSelector);
      const $draggedItem = $affirmationWrappers.eq(oldIndex);
      const $targetItem = $affirmationWrappers.eq(newIndex);

      console.log($draggedItem.attr('id'));
      console.log($targetItem.attr('id'));

      if (newIndex < oldIndex) {
        // $draggedItem.insertBefore($targetItem);
        $targetItem.insertBefore($draggedItem);
      } else {
        $targetItem.insertAfter($draggedItem);
        // $draggedItem.insertAfter($targetItem);
      }

      // Call the jQuery function to get the new order of affirmations within the collection
      

      setTimeout(function(){
        const newOrder = getAffirmationsOrder(collectionId);
        console.log(newOrder);

        const fd = new FormData();


        fd.append('collection_id', collectionId);
        fd.append('order', newOrder);

      axios.post(`/api/update_order.php`, fd, {
        }).then(res => {
            console.log(res);
            if (res.data.success === 1) {
                swal("Success!", res.data.message, "success");
                // setTimeout(function(){
                //     history.push("/manage_collections/id/"+collectionId);
                // }, 1500);
            } else if (res.data.success === 0) {
                swal("Error!", res.data.message, "error");
            }
        }).catch(function (error) {
            if (error.response) {
            var errors = error.response.data.errors;
            var err_txt = "";
            for (const property in errors) {
                err_txt += property + "-" + errors[property];
            }

            swal(error.response.data.message, err_txt, "error");
            }
        });


      }, 1500);
      
    }
  };

  const getAffirmationsOrder = (collectionId) => {
    const order = [];
    $(`#affirmations_wrapper_${collectionId} .affirmation_wrapper`).each(function () {
      const affirmationId = $(this).find('.btn_edit').data('affirmation_id');
      order.push(affirmationId);
    });
    return order;
  };


  /////////////////////////////////////////

  useEffect(() => {
    axios.get(url,
      {
        headers: {
          'Accept': 'application/json'
        }
      }
    ).then(res => {
      console.log(res);
      if (res.status === 200) {
        if(typeof props.match.params.q !== "undefined"){
          setCollections(res.data);
        }else{
          setCollections(res.data.data);
        }
        

        if(typeof res.data.from !== "undefined"){
          setFrom(res.data.from);
          setTo(res.data.to);
          setTotal(res.data.total);
          setLinks(res.data.links);

          if(res.data.next_page_url !== null){
            var next_page_url_parts = res.data.next_page_url.split("?");
            setNext(next_page_url_parts[1].replace("page=", ""));
          }else{
            setNext("");
          }
          

          if(res.data.prev_page_url !== null){
            var previous_page_url_parts = res.data.prev_page_url.split("?");
            setPrevious(previous_page_url_parts[1].replace("page=", ""));
          }else{
            setPrevious("");
          }
        }

        
        setLoading(false);
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAffirmation = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Deleting";


    swal({
      title: "Delete Affirmation",
      text: "Are you sure you want to delete this affirmation",
      icon: "warning",
      buttons: [
        'Cancel',
        'DELETE!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        axios.get(`/api/delete_collection_affirmation.php?id=${id}`, {
          headers: {
            'Accept': 'application/json'
          }
        }).then(res => {
          console.log(res);
          if (res.status === 200) {
            swal("Deleted!", "Deleted successfully", "success");
            thisClicked.closest(".affirmation_wrapper").remove();
          } else if (res.status === 404) {
            swal("Error", res.data.message, "error");
            thisClicked.innerText = "Delete";
          }
        });
      } else {
        thisClicked.innerText = "Delete";
      }
    });

  }



  const deleteCollection = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Deleting";


    swal({
      title: "Are you sure you want to delete this collection? This will also delete all the affirmations linked to this collection",
      text: "You will not be able to recover this collection",
      icon: "warning",
      buttons: [
        'Cancel',
        'DELETE!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        axios.get(`/api/delete_collection.php?id=${id}`, {
          headers: {
            'Accept': 'application/json'
          }
        }).then(res => {
          console.log(res);
          if (res.status === 200) {
            swal("Deleted!", "Deleted successfully", "success");
            thisClicked.closest("tr").remove();
          } else if (res.status === 404) {
            swal("Error", res.data.message, "error");
            thisClicked.innerText = "Delete";
          }
        });
      } else {
        thisClicked.innerText = "Delete";
      }
    });

  }

  const handleSearch = (e) => {
    e.persist();
    setKeyword(e.target.value);
  }

  setTimeout(function(){
    if(typeof props.match.params.id !== "undefined"){
        var id = props.match.params.id;
        $('#affirmations_wrapper_'+id).find('.btn_view').trigger('click');
    }
  }, 1500);


  const doSearch = (e) => {
    e.preventDefault();

    const data = {
      q: keyword
    }

    history.push(`/search/type/collections/q/${keyword}`)
  }

  // console.log(links);


  if (loading) {
    return <h4>Loading Collections Data...</h4>
  } else {
    var collection_HTMLTABLE = "";

    collection_HTMLTABLE = collections.map((item, index) => {
      return (
        <CTableRow key={index}>
          <CTableDataCell>{item.id}</CTableDataCell>
          <CTableDataCell>{item.name}</CTableDataCell>
          <CTableDataCell><img style={{width: '50px'}} src={api_url + item.image}/></CTableDataCell>
          <CTableDataCell>{item.description}</CTableDataCell>
          <CTableDataCell>{item.categories}</CTableDataCell>
          <CTableDataCell>
            <div>
                <CButton className='btn_view' onClick={handleClick} data-collection_id={item.id} color="primary" size="sm" >View</CButton>&nbsp;|&nbsp;
                <CButton  className='btn_add' onClick={AddAffirmation} data-collection_id={item.id} color="primary" size="sm" >Add</CButton>
            </div>
            <br/>
            <div className='affirmations_wrapper' id={'affirmations_wrapper_' + item.id} style={{display: 'none'}} key={item.id} data-collection_id={item.id}>
                {
                item.affirmations.map((affirm, index) => {
                    return (
                        <div draggable="true" id={"aff"+index}
                            onDragStart={(e) => handleDragStart(e, item.id, index)} 
                            onDragOver={(e) => handleDragOver(e)} 
                            onDrop={(e) => handleDrop(e, item.id, index)} 
                            className='affirmation_wrapper' style={{display: 'flex', justifyContent: 'space-between', padding: '0 10px', border: '1px solid'}}>
                            <span className='affirmation_text'>• {affirm.text}</span>
                            <div>
                                <a style={{cursor: 'pointer'}} onClick={editAffirmation} data-affirmation_id={affirm.id} data-value={affirm.text} className='btn_edit' color="primary" size="sm" >Edit</a> &nbsp;
                                <a style={{cursor: 'pointer'}} onClick={(e) => deleteAffirmation(e, affirm.id)} data-affirmation_id={affirm.id} className='btn_remove' color="primary" size="sm" >Delete</a>
                            </div>
                        </div>
                    )
                })
                }
            </div>
          </CTableDataCell>
          <CTableDataCell>
            <CButton component="a" href={`/edit-collection/id/${item.id}`} color="primary" size="sm" >Edit</CButton>
          </CTableDataCell>
          <CTableDataCell>
            <CButton onClick={(e) => deleteCollection(e, item.id)} color="danger" size="sm" >Delete</CButton>
          </CTableDataCell>
        </CTableRow>
      );
    });
  }

  links.map(function (link, index) {
    links[index].label = link.label.replace("&laquo;", "<").replace("&raquo;", ">");
  })

  return (
    <div className='container_wrapper'>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4>Manage Collections</h4>
              </div>
              <div className="card-header">
                {/* <form className='float-start' >
                  <input type="text" name='q' defaultValue="" className='mr-2' onChange={handleSearch} />
                  <CButton type='button' onClick={doSearch} color="primary" size="sm" className='float-end'>Search</CButton>
                </form> */}

                <CButton component="a" href={'/'} color="danger" size="sm" className='float-end ms-2'>Back</CButton>
                <CButton component="a" href={'/add-collection'} color="success" size="sm" className='float-end'>Add Collection</CButton>
              </div>
              <div className="card-body table-responsive">

                <CTable hover striped small bordered>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>ID</CTableHeaderCell>
                      <CTableHeaderCell>Name</CTableHeaderCell>
                      <CTableHeaderCell>Image</CTableHeaderCell>
                      <CTableHeaderCell>Description</CTableHeaderCell>
                      <CTableHeaderCell>Categories</CTableHeaderCell>
                      <CTableHeaderCell>Affrimations</CTableHeaderCell>
                      <CTableHeaderCell>Edit</CTableHeaderCell>
                      <CTableHeaderCell>Delete</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {collection_HTMLTABLE}
                  </CTableBody>
                </CTable>

              </div>
              {
                // typeof props.match.params.q === "undefined" ? 

                //     (<Pagination base_url="manage-collections" from={from} to={to} total={total} links={links} previous_number={previous_number} next_number={next_number}></Pagination>)
                //   :
                //   <div/>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageCollections;